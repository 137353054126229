<template lang="pug">
div
  van-form
    component(v-for="(vf, vfIndex) of fields"
      :key="vfIndex" 
      :is="vf.component ? vf.component : 'van-field'"
      :label="vf.label" 
      v-model="data[vf.key]"
      v-bind="vf.attrs" 
    )
    van-row.row
      van-button.col(type='info' block round @click="onSubmit") 提交
</template>

<script>
import formmixin from '../form'

export default {
  mixins: [formmixin],
  name: 'ProjectInfoEdit',
  props: ['value','isedit'],
  data() {
    return {
      data: {
        // number: '',
        // invitation_time: 0,
        // delivery_time: 0,
        // category: '', // 采购方式 
        // intro: '',
        // risk_analysis: '',
        // remark: '',
        // urls: [],
        // furniture_types: '', // 产品类型 办公  酒店  教学  医养  金融  家用  其他  
        project_types: '', // 项目类型   油漆类  胶板类 软体类  金属类  其他类
      },
      fields: [
        { 
          label: '项目编号：', 
          key: 'number',
        },
        { 
          label: '预招标时间：', 
          key: 'invitation_time',
          component: 'picker-data' ,
         },
        { 
          label: '预交付时间：', 
          key: 'delivery_time',
          component: 'picker-data' ,
        },
        { 
          label: '项目类型：', 
          key: 'project_types',
          component: 'picker-select' ,
          attrs: {
            isedit: true,
            list: [ '办公', '酒店', '教学', '医养', '金融', '家用', '其他'],
            callback: (item)=>{
              this.data.project_types = item
              this.$set(this.data, "project_types", item)
            }
          }
        },
        { 
          label: '产品类型：', 
          key: 'furniture_types',
          component: 'picker-select',
          attrs: {
            isedit: true,
            list: [ '油漆类', '胶板类', '软体类', '金属类', '其他类'],
            callback: (item)=>{
              this.data.furniture_types = item
            } 
          } 
        },
        { 
          label: '采购方式：', 
          key: 'category',
          component: 'picker-field' ,
          attrs: {
            state_type: 'report_projecttype'
          }
        },
        { 
          label: '项目介绍：', 
          key: 'intro',
        },
        { 
          label: '风险分析：', 
          key: 'risk_analysis',
        },
        { 
          label: '项目备注：', 
          key: 'remark',
        },
        {
          name: '添加附件',
          key: 'urls',
          component: 'UploaderField'
        }
      ],
    }
  },
  methods: {
    onSubmit() {
      this.$emit('onEditInfo', this.data)
    },
    init(newValue){
      if(!newValue) return
      let {number, invitation_time, delivery_time, category, types, intro,risk_analysis, remark,urls, furniture_types, project_types} = newValue
      urls = urls.map(item=>{
        return {
          url:item
        }
      })
      this.data = {number, invitation_time, delivery_time, category, intro,risk_analysis,remark,urls , furniture_types, project_types}
    }
  },
  watch: {
    value(v) {
      this.init(v)
    }
  },
  computed: {
    projectType() {
      let result =  this.$store.getters.GetConst('report_projecttype')
      return result
    },
  },
  mounted() {
    if(this.value) {
      this.init(this.value)
    }
  }
}
</script>