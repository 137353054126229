<template lang="pug">
div
  van-form(@submit='onSubmit')
    van-cell-group(v-for="(g, gIndex) of form.groups" :key="gIndex" ) 
      component(v-for="(vf, vfIndex) of g.fields"
        :key="vfIndex" 
        :is="vf.component ? vf.component : 'van-field'"
        :label="vf.name" 
        v-model="data[vf.key]" 
        v-bind="vf.attrs" 
        @click="vf.click ? vf.click($event, vf) : ''"
        clearable
      )
    div(style='margin: 16px;')
      van-button.col(type='info' native-type='submit' block round ) 提交
</template>

<script>
import {
  vantToDpProvince, 
  vantToDpCity, 
  vantToDpDistrict,
  getVantAddress
} from '../../lib/helpers/AreaHelper'
import formmixin from '../form'

export default {
  mixins: [formmixin],
  name: 'ProjectBaseEdit',
  props: ['value'],
  data() {
    return {
      form: {
        groups: [
          {
            fields: [
              {
                name: '项目名称：',
                key: 'name',
                attrs: {
                  readonly: true,
                  rules: [
                    {required: true, message: '请输入项目名称'}
                  ]
                }
              },
              // {
              //   name: '甲方名称：',
              //   key: 'first_party_name',
              //   attrs: {
              //     rules: [{required: true, message: '请输入甲方名称'}]
              //   }
              // },
              {
                name: '项目区域：',
                key: 'area',
                component: 'AreaField',
              },
              {
                name: '详细地址：',
                key: 'address'
              },
              {
                name: '项目金额：',
                key: 'amount',
                component: 'SuffixField',
                attrs: {
                  type: 'number',
                  suffix:'万元'
                }
              },
              {
                name: '项目可行性：',
                key: 'feasibility',
                component: 'RateField'
              },
              {
                name: '项目有效期：',
                key: 'validity',
                component: 'PickerField',
                attrs: {
                  type: 'constant',
                  state_type: 'report_valtime'
                }
              },
              {
                name: '简单描述：',
                key: 'sketch',
                attrs: {
                  type: 'textarea'
                }
              },
            ]
          }
        ]
      },
      data: {
        id: 0,
        name: '',
        address: '',
        amount: '',
        feasibility: 3,
        first_party_name: '',
        validity: 'three_month',
        province: '',
        city: '',
        district: '',
        sketch: '',
        area: [],
      }
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  methods: {
    async onSubmit() {
      try{
        let province_poi_province = (await vantToDpProvince(this, this.data.area[0].name)).id
        let city_poi_city = (await vantToDpCity(this, this.data.area[1].name, province_poi_province)).id
        let district_poi_district = (await vantToDpDistrict(this, this.data.area[2].name, city_poi_city)).id
        this.data.desc = `${this.data.area[0].name}${this.data.area[1].name}${this.data.area[2].name}${this.data.address}`
        this.data = {
          ...this.data,
          province: { id: province_poi_province,ProvinceName: this.data.area[0].name },
          city: { id: city_poi_city,CityName: this.data.area[1].name },
          district: { id: district_poi_district,DistrictName: this.data.area[2].name },
          province_poi_province,
          city_poi_city,
          district_poi_district
        }
        this.$emit('onSubmitInfo', this.data)
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    init() {
      let{ province, city, district, state} = this.value
      if (state && (state == 'wait' || state == 'wait_handle' || state == 'had_handle')) {
        this.form.groups[0].fields[0].attrs.readonly = false
      }
      let area = getVantAddress(province.ProvinceName, city.CityName, district.DistrictName)
      this.data = {...this.value,area:area}
    }
  },
  mounted() {
    this.init()
  }
}
</script>
