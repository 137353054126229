<template lang="pug">
div
  van-field(
    v-for='(item, index) of fields'
      :key= "index"   
      :label='item.label'
      :value='data[item.key]'
      disabled
    )
  van-field(label="上传附件:" readonly)
  van-grid(:column-num='4' :square='true')
    van-grid-item(v-for='(url, index) in (data.urls)' :key="index")
      van-image(
        :width='70'
        :height='70'
        :src='url'
        @click='PreViewImg(url)'
      )
</template>

<script>

import {previewSize} from '../../lib/helpers/OssImageHelper'
import {ImagePreview} from 'vant'

export default {
  name: 'ProjectInfo',
  props: ['value','isedit'],
  data() {
    return {
      previewSize,
      data: {
        number: '',
        invitation: '',
        delivery: '',
        category: '',
        types: '',
        intro: '',
        risk_analysis: '',
        remark: '',
        urls: []
      },
      fields: [
        { label: '项目编号：', key: 'number' },
        { label: '预招标时间：', key: 'invitation' },
        { label: '预交付时间：', key: 'delivery' },
        { label: '项目类型：', key: 'project_types' },
        { label: '产品类型：', key: 'furniture_types' },
        { label: '采购方式', key: 'categorytype' },
        { label: '项目介绍：', key: 'intro' },
        { label: '风险分析：', key: 'risk_analysis' },
        { label: '项目备注：', key: 'remark' },
      ],
    }
  },
  methods: {
    PreViewImg(img){
      ImagePreview({
        images: [
          img
        ],
         closeable: true,
      })
    },
    init(newValue){
      this.data = newValue
      this.data.categorytype = this.projectType[this.value.category]
      this.data.invitation = this.dataFormat(this.value.invitation_time, 'YYYY-MM-DD')
      this.data.delivery = this.dataFormat(this.value.delivery_time, 'YYYY-MM-DD')
    }
  },
  watch: {
    value() {
      this.init(this.value)
    }
  },
  computed: {
    projectType() {
      let result =  this.$store.getters.GetConst('report_projecttype')
      return result
    },
  },
  mounted() {
    if(this.value) {
      this.init(this.value)
    }
  }
}
</script>