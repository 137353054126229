<template lang="pug">
div(style='margin-bottom:60px;')
  val-pull-refresh(
    ref="list"
    v-model='loading'
    @refresh="getListData"
  )
    van-swipe-cell(v-for="(offer, index) of list" 
      :key="index"
      stop-propagation)
      van-card.van-hairline--bottom(
        v-bind="offer"
        :tag='offer.tag'
        @click="$router.push({name: 'offerdetail', query: {id: offer.quote_poi_quote}})"
        )
        template(#title)
        template(#desc)
          van-tag(plain type='danger') 报价单名称:  {{ offer.title}}
          .label 公司：{{offer.company_name}}
          .label 收货人: {{offer.receipt}}
          .label 更新日期: {{offer.update_time | dataFormat}}
      template(#right='')
        van-button(square='' type='danger' text='删除' style="height:100%;" @click="onDelete(offer)" )
  van-goods-action
    van-goods-action-button(type='info', text='关联报价单', @click='onAddQuoteRelation')

  van-action-sheet(v-model='show', title='选择报价单')
    van-checkbox-group(ref="checkboxGroup" v-model='selectQuotes')
      van-cell-group
        van-cell(v-for='(item, index) in sellerQuoteList' 
          clickable 
          :key='item' 
          :title='`名称：${item.title}`'
          @click='toggle(index)'
          )
          template(#right-icon='')
            van-checkbox(:name='item' ref='checkboxes')
    div(style='margin: 16px;')
      van-button(round='', block='', type='info', native-type='submit' @click='onSubmit()' :disabled='commitState')
        | 提交

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectQuoteRelation',
  props: ['value', 'projectId'],    // vlaue = ofc_companys_id 客户id
  data() {
    return {
      loading: false,
      commitState: false,
      selectQuotes: [],
      show: false,
      data: [],
      list: [],
      sellerQuoteList: [],
      newData:{
        remark: '',
        create_time: 0,
        images: []
      },
      v:[]
    }
  },
  watch: {
    value() {
      this.getSellerQuoteList()
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  methods:{
     async getListData() {
      try {
        this.loading = true
        const params = {
          where: { 
            project_poi_projects: this.projectId
          },
          with: {
            include: [{
              table: 'quote_poi_quote',
              keys: 'id,seller_poi_sell_users,number,title,cus_com_poi_ofc_cus_companys,create_time,update_time,remark,company_name,receipt,quote_state',
              relation: [{
                table: 'quote_item',
                key: 'quote_poi_quote',
                keys: 'id, quote_poi_quote, thumbnail_url',
                limit: 1,
                needcount: 0
              }]
            }]
          },
          needcount: 0
        }
        let res = await this.$api.GetProjectQuoteRelation(params)
        this.loading = false  
        this.list.data 
        const flowStatus = (await this.getFlow('quote_state')).status
        this.list = res.data.map((item) => {
          let state = item.quote_poi_quote.quote_state
          return {
            ...item.quote_poi_quote,
            id: item.id,  
            quote_poi_quote: item.quote_poi_quote.id,
            tag: state ? flowStatus[state].alias : '',
            thumb: (item.quote_poi_quote && item.quote_poi_quote.quote_rel_quote_item && item.quote_poi_quote.quote_rel_quote_item.length > 0) ? item.quote_poi_quote.quote_rel_quote_item[0].thumbnail_url : '',
          }
        })
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },

    /**
     * 获取销售的所有可以关联到当前项目的报价单
     */
    async getSellerQuoteList() {
      const param = {
        where: { 
          seller_poi_sell_users: this.sellerId,
          log_to: 0
          // id: {
          //   $ne: this.quoteData.map(({ quote_poi_quote }) => quote_poi_quote).join(',')
          // }
        },
        keys: 'id,seller_poi_sell_users,number,title,cus_com_poi_ofc_cus_companys,create_time,update_time,remark'
      }
      if(this.value && this.value > 0) {
        param.where.cus_com_poi_ofc_cus_companys = this.value
      }
      const res = await this.$api.GetQuote(param)
      this.sellerQuoteList = res.data.items
    },

    async onSubmit(){
      this.commitState = true
      // 添加数据
      if (this.selectQuotes) {
        try {
          let data = 
          {
            id: this.projectId,
            project_rel_project_quote_relation: this.selectQuotes.map((item) => {
              return {
                quote_poi_quote: item.id,
                project_poi_projects: this.projectId
              }
            })
          }
          await this.$api.CreatePorjectQuoteRelation(data)
          await this.getListData()
          this.$toast("添加关联成功!")
        } catch (ex) {
          console.log(ex)
        }
      }
      this.show = false
      this.commitState = false  
    },
    onAddQuoteRelation() {
      this.selectQuotes = [] 
      this.show = true
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    onDelete(obj) {
      try {
        this.$dialog.confirm({
          title: '',
          message: '确认移除关联的报价单吗？',
        }).then(async () => {
          try {
              await this.$api.DeleteProjectQuoteRelation(obj.id)
              this.list = this.list.filter((p) => {
                return p != obj
              })
              this.$toast('移除成功')
            } catch(ex) {
              this.$toast(this.$error(ex))
            }
        })
        .catch(() => {})
      } catch (ex) {
        console.log(ex)
      }
    }
  },

  mounted() {
    this.getListData()
    this.getSellerQuoteList()
  }
}
</script>