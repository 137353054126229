<template lang="pug">
div(style='margin-bottom:60px;')
  van-steps(direction='vertical', :active='data.length-1' active-color="#38f")
    van-step(v-for="(step,sIndex) of data" :key="sIndex" )
      span 备注：{{step.remark}}
      P 创建日期：{{step.create_time}}
      P(v-if="step.files.length > 0") 附件详情： 
      van-cell(v-for="(item, index) in step.files" :key="index" :title="item" size='small' :border='false')
        template(#title)
          span.label {{index + 1}}： {{item.name}}
        template(#right-icon)
          van-icon.search-icon(name='down' @click='onDownload(item.file_url, item.name)') 
      p(v-if="step.files.length > 0")
        //- van-grid(:column-num='8' :square='true')
          van-grid-item(v-for="(url, index) in step.files" :key='index') 
            van-image(
              :src='url'
              @click='PreViewImg(url)')
  van-goods-action
    van-goods-action-button(type='info', text='添加进度', @click='onAddTrack')

  van-action-sheet(v-model='show', title='进度跟踪')
    van-field(v-model='newData.remark', rows='1', autosize, type='textarea', placeholder='请输入最新的跟踪记录')
    uploader-field(v-model='newData.images' :value ='newData.images')
    div(style='margin: 16px;')
      van-button(round='', block='', type='info', native-type='submit' @click='onSubmit()' :disabled='commitState')
        | 提交

</template>

<script>

import {ImagePreview} from 'vant'
export default {
  name: 'ProjectTrack',
  props: ['value', 'projectId'],
  data() {
    return {
      commitState: false,
      show: false,
      data: [],
      newData:{
        remark: '',
        create_time: 0,
        images: []
      },
      v:[]
    }
  },
  computed: {
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
  },
  methods:{
    async onSubmit(){
      this.commitState = true
      let time = Date.parse(new Date())
      let isDone = true
      this.newData.images.map(item=>{
          if(item.status !== 'done'){
            isDone = false
          }
        })
      if(!isDone){
        this.$toast('请确认已经上传完毕')
      }
      else{
        let param = {
          project_poi_projects: this.projectId,
          seller_poi_sell_users: this.sellerId,
          remark: this.newData.remark,
          track_files: JSON.stringify(this.newData.images.map(item=>{
            return {
              id: 0,
              file_url: item.url,
              name: item.name,
            }
          }))
        }
     
        try{
          await this.$api.PostProjectTrack(param)
          this.show = false
          this.data.unshift({
            remark: this.newData.remark,
            create_time: this.dataFormat(time),
            files: this.newData.images.map(item=>{
              return {
                ...item,
                file_url: item.url
              }
            })
          })
          this.newData.remark = ''
          this.newData.images = []
          this.$toast('添加成功')
        }catch(ex) {
          this.data.shift()
          this.$toast(this.$error(ex))
        }
      }
      this.commitState = false  
    },

    onAddTrack() {
      this.show = true
    },

    PreViewImg(img) {
      ImagePreview({
        images: [
          img
        ],
         closeable: true,
      })
    },

    init() {
      if(this.value) {
        this.data = this.value.map(item=>{
          return {
            remark: item.remark,
            create_time: this.dataFormat(item.create_time),
            files: item.imgaes_rel_project_track_files.items
          }
        })
        this.data.reverse()
      }
    },

    // 下载附件
    onDownload(url, name) {
      var link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      // link.remove()
      document.body.removeChild(link)
    }
  },
  
  watch: {
    value() {
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style scoped lang='less'>
.label {  
  width: 70vw;  
  display: block;  
  overflow: hidden;  
  white-space: nowrap;  
  text-overflow: ellipsis; 
}
</style>