<template lang="pug">
div
  van-field(v-for="(field, index) of fields" :key="index"
    :label="field.label"
    v-model="fields_data[field.key]"
    clearable
    v-bind="field.attrs"
    )
  van-row.row
    van-button.col(type='info' native-type='submit' block round  @click='onCallBack') 提交
</template>

<script>
export default {
  name: 'FieldListEdit',
  props: ['value'],
  data() {
    return {
      fields: [],
      fields_data: {}
    }
  },
  methods: {
    onCallBack() {
      this.$emit('onCallBack', this.fields_data)
    },
    init() {
      let { fields,fields_data } = this.value
      this.fields = fields
      this.fields_data = fields_data
    }
  },
  watch: {
    value() {
      this.init()
    }
  },
  mounted() {
    this.init()
  }
}
</script>