<template lang="pug">
div
  van-overlay(:show="loading")
    van-loading
  van-card.goods-card( 
    :title= 'info.title'
    :centered='true'
    @click='onEditBaseClick'
    )
    template(#title='') 
      van-row(type="flex" justify="center")
        h3.row(style='color:red') {{`${parseInt(info.amount)}万元`}} 
        h3.row ·{{info.name}}
      van-row(type="flex" justify="center")
        van-rate.row(readonly :count='info.feasibility' void-icon="star" void-color="#ffb400")
      //- van-row(type="flex" justify="center")
      //-   h3.row {{info.first_party_name}}
      van-row(type="flex" justify="center")
        h3.row {{info.desc}}
      van-row(type="flex" justify="center")
        h3.row {{info.sketch}}
      van-row(type="flex" justify="space-between")
        span.row 有效期{{valtimeState[info.validity]}}
        span.row 创建时间：{{info.create_time | dataFormat("YYYY-MM-DD")}}
  van-row.row(type="flex" justify="center" v-if="state == 'wait' || state == 'had_reset' || state == 'rescinded'" )
    van-col(span="12")
      van-button.col(type='info' native-type='submit' block round  @click='gotoReport') 去报备
  van-tabs(v-model='active' sticky offset-top='40')
    van-tab(title="项目介绍")
      van-cell-group(v-for="(group, index) of groups" 
        :key="index"
        :title="group.title"
        )
        template(#title)
          van-row(type='flex', justify='space-between')
            van-col(span='12') 
              span {{group.title}}
            van-col( v-if='showEdit' @click='onEdit(group.key)') 
              van-icon( name="edit" color="#1989fa")
              span(style='color:#1989fa') 编辑
        component(:is="group.template", v-for="(field, index) of group.fields"
          :key="index" 
          :value='data[group.key][field.key]' 
          :label='field.label' 
          :disabled='!power || !is_edit'
        )
    van-tab(title="报备记录")
      van-steps(direction='vertical', :active='0' active-color="#38f")
        van-step(v-for="(step,sIndex) of steps" :key="sIndex" )
          h3 {{step.operator}} {{step.flow_state}}项目
          p {{step.updated_at }}
          p 备注: {{step.flow_remark}}
    van-tab(title="进度跟踪")
      project-track(:value='tracks', :projectId='id')
    van-tab(title="报价记录")
      project-quote-relation(:value='ofcCompanysId' :projectId='id')
  van-action-sheet(v-if='power && is_edit' v-model='is_edit', :title='editTitle[editType]')
    div(v-if='editType==="base"')
      project-base-edit(:value='info' @onSubmitInfo='onBaseEdit')
    div(v-else-if='editType==="first_party"')
      first-party-contact(:value='linkman' :isComponent='true' :submit='true' @onSave='onLinkman')
    div(v-else-if='editType==="project"')
      project-info-edit(:value='data.project.list' :isedit="true" @onEditInfo='onEditInfo')
    div(v-else-if='editType==="report_man"')
      field-list-edit(:value='reportManValue' :isedit="true" @onCallBack='onReportman')
    div(v-else-if='editType==="other"')
      field-list-edit(:value='secondPartyValue' :isedit="true" @onCallBack='onSecondPart')

  van-dialog(
    v-model='isdelete'
    title="是否删除方案"
    @confirm='deleteProject'
    show-cancel-button
    @cancel='isdelete=false')
   
</template>

<script>
import FieldListEdit from '../../components/other/FieldListEdit'
import ProjectInfoEdit from '../../components/other/ProjectInfoEdit'
import ProjectBaseEdit from '../../components/other/ProjectBaseEdit'
import ProjectTrack from '../../components/other/ProjectTrack'
import ProjectInfo from '../../components/other/ProjectInfo'
import ProjectQuoteRelation from '../../components/other/ProjectQuoteRelation'
import FirstPartyContact from '../../components/other/FirstPartyContact'
import { mapGetters } from 'vuex'
export default {
  components: {
    FieldListEdit,
    ProjectInfoEdit,
    ProjectBaseEdit,
    ProjectTrack,
    ProjectInfo,
    ProjectQuoteRelation,
    FirstPartyContact
  },
  data() {
    return {
      id: 0,
      loading: false,
      state: '', // 报备状态、
      power: false, // 是否有权限编辑
      showEdit: false,
      is_edit: false,
      isdelete: false,
      editType: 'base', // base  linkman reportman other
      editTitle:{
        base: '基础信息',
        first_party: '甲方联系人',
        project: '项目信息',
        report_man: '报备人信息',
        other: '竞争者信息'
      },
      ofcCompanysId: 0,     // 客户id
      active: 0,
      groups: [
        {
          title: '甲方信息',
          key: 'first_party',
          template: 'first-party-contact',
          fields: [
            { key: 'info'}
          ]
        },
        {
          title: '项目信息',
          key: 'project',
          template: 'project-info',
          fields: [
            { key: 'list' },
          ]
        },
        {
          title: '报备人信息',
          key: 'report_man',
          template: 'van-field',
          fields: [
            { label:'姓名：', key:'name', 
             attrs: {
                  disabled: true
                }
              },
            { label:'项目关系：', key:'project_relation'},
            { label:'期望提成：', key:'royalties_expectation'},
            { label:'项目优势：', key:'strengths'},
            { label:'联系电话：', key:'tel'},
            { label:'联系邮箱：', key:'email'},
          ]
        },
        {
          title: '竞争信息',
          key: 'other',
          template: 'van-field',
          fields: [
            { label:'乙方对手：', key:'competitor'},
            { label:'项目亮点：', key:'competitor_strengths'},
            { label:'形势预测：', key:'competitor_projections'},
          ]          
        }
      ],
      data: {
        first_party: {
        },
        project: {

        },
        report_man: {

        },
        other: {

        },
      },
      info: {
          name:'',
          amount: 0,
          address: '',
          // first_party_name: '',
          feasibility: 1,
          validity: 0,
          sketch: '',
          province: 1,
          city: 1,
          district: 1,
          state: ''
      },
      // reportState :{
      //   adopt: '采纳',
      //   had_reset: '驳回',
      //   reject: '拒绝',
      //   had_handle: '受理',
      //   wait_handle: '报备',
      //   wait: '新建',
      //   rescinded: '撤回',
      //   shutdown: '关闭',
      //   overdue: '过期',
      // },
      steps: [],
      tracks: [],
      flowStatus: {},
      flowStep: []        //
    }
  },
  computed: {
    ...mapGetters(['getFlow']),
    valtimeState() {
      let result =  this.$store.getters.GetConst('report_valtime')
      return result
    },
    sellerId() {
      return this.$store.state.userInfo.seller_poi_sell_users
    },
    linkman() {
      return this.data.first_party.info
    },
    reportManValue() {
      let result = {
        fields: this.groups.find(p=>p.key ==='report_man').fields,
        fields_data: this.data.report_man
      }
      return result
    },
    secondPartyValue() {
        let result = {
        fields: this.groups.find(p=>p.key ==='other').fields,
        fields_data: this.data.other
      }
      return result
    }
  },
  watch: {
    active(value){
      if(value == 1) {
        this.getProjectRecord()
      }
    }
  },
  methods: {
    onEdit(v) {
      this.is_edit = true
      this.editType = v
    },
    // 去报备
    async gotoReport() {
      try {
        let tmpStep = this.flowStep.find(p=> p.name == 'wait' && p.next_node == 'wait_handle')
        if(tmpStep) {
          let data = {
            actionId: tmpStep.id,
            id: this.id,
          }
          await this.$api.UpdateFlowStep(data)
          this.state = tmpStep.next_node
        } else {
          await this.$api.PostProjectReport(this.id)
          this.state = 'wait_handle'
        }
        this.$toast('报备成功')
        this.refresh()
      } catch (ex) {
        this.$toast(this.$error(ex))
      }
    },
    async getProjectDetail() { 
      let params = {
        id: this.id,
        seller_id: this.sellerId
      }
      this.loading = true
      try {

        let res = await this.$api.GetProjectDetail(params)
        let {
          project_rel_project_first_party_linkman:linkman,
          project_rel_project_furniture_types:furniture_types,
          project_rel_project_attachment: attachment,
          project_rel_project_reportman: reportman,
          second_party_competitor, competitor_strengths, competitor_projections,competitor,
          name,amount,feasibility,sketch,address,validity,create_time,first_party_name, id_poi_ofc_cus_companys,
          province_poi_province, city_poi_city, district_poi_district,state
        } = res.data
        first_party_name = first_party_name || (id_poi_ofc_cus_companys || {}).fullname
        let desc = `${province_poi_province.ProvinceName}${city_poi_city.CityName}${district_poi_district.DistrictName}${address}`
        this.info = {name,address, amount,first_party_name,feasibility:parseInt(feasibility),sketch,desc:desc, 
        validity,create_time,province:province_poi_province,city:city_poi_city,district:district_poi_district,state }
        this.ofcCompanysId = id_poi_ofc_cus_companys
        let first_part_info = {
          ofc_cus_companys: {
            id: id_poi_ofc_cus_companys,
            text: first_party_name,
          },
          linkman: linkman.items
        }
        this.data.first_party = {
          info: first_part_info
        }
        let project_types = (res.data.project_rel_project_types || {}).items || []
        let project = {
          list:{
            ...res.data,
            furniture_types: furniture_types.items.map(({name}) =>{ return name }).join(','),
            project_types: project_types.map(({name}) =>{ return name }).join(','),
            urls: attachment.items.map(({file_url}) => {return file_url})
          }
        }
        this.data.project = project
        this.data.report_man = ((reportman.items)[0]) || {}
        this.data.other = {second_party_competitor, competitor_strengths, competitor_projections,competitor}
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    // 进度跟踪
    async getProjectTrack() { 
      try{
        let params = {
            where : {
              project_poi_projects: this.id
            },
            with: {
                relation:[
                    {
                        table: "project_track_files", 
                        key:"imgaes_poi_project_track"
                    }
                ]
            }
        }
        let res = await this.$api.GetProjectTrack(params)
        this.tracks = res.data.items
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    // 获取报备记录
    async getProjectRecord() { 
      this.steps = []
      let param = {id:this.id, name: this.info.name}
      let res = await this.$api.GetProjectRecord(param)
      this.steps = res.data.items.map(item=>{
        return {
          operator: item.operator,
          
          flow_state: (item.uid_poi_users ? "提交" : "" )+ this.flowStatus[item.flow_state].alias,
          flow_remark: item.flow_remark,
          update_time: item.update_time
        }
      })
    },
    onEditBaseClick() {
      if(!this.power) return
      this.is_edit = true
      this.editType = 'base'
    },
    async onLoad() {
      try{
        await this.getProjectDetail()
        await this.getProjectTrack()
      }catch(ex){
        this.$toast(this.$error(ex))
      }
    },
    // 基本信息编辑
    async onBaseEdit(v) {
      try{
        v.id = this.id
        await this.$api.PutProject(v)
        this.info = {...v}
        this.is_edit = false
      }catch(ex){
        this.$toast(this.$error(ex))
      }
    },
    // 更新甲方联系人信息
    async onLinkman(v) {
      try {
        let { info } = this.data.first_party
        let {ofc_cus_companys, first_ofc_linkman, second_ofc_linkman} = v
      
        if(ofc_cus_companys) {
          this.ofcCompanysId = ofc_cus_companys.id
          if(ofc_cus_companys.id != info.ofc_cus_companys.id 
            && ofc_cus_companys.text != info.ofc_cus_companys.text ) {
              // 更新
              let data = {
                id: this.id,
                id_poi_ofc_cus_companys: ofc_cus_companys.id,
                first_party_name: ofc_cus_companys.text
              }
              let res = await this.$api.PutProject(data)
          }
          let ids = info.linkman.map( ({id})=> {
            return id
          })
          let linkman = []
          if(first_ofc_linkman) {
            let itemId = ids.pop()
            let result = await this.checkLinkman(first_ofc_linkman, itemId)
            linkman.push(result)
          }
          if(second_ofc_linkman) {
            let itemId = ids.pop()
            let result = await this.checkLinkman(second_ofc_linkman, itemId)
            linkman.push(result)
          }
          ids.forEach(async (id)=> {
            await this.DeleteLinkman(id)
          })
          let newInfo = {
            ofc_cus_companys,
            linkman,
          }
          this.$set(this.data.first_party,'info',newInfo)
          this.is_edit = false
          this.$toast('更新成功')
        } 
      } catch (ex) {
        console.log(ex)
        this.$toast(this.$error(ex))
      }
    },
    async checkLinkman(info, id) {
      try{
        info.id = parseInt(id || 0)
        info.project_poi_projects = this.id
        // put
        if(info.id > 0) {
          let res = await this.$api.PutLinkMan(info)
        } else { // post
          let res = await this.$api.PostLinkMan(info)
          info.id = res.data.id
        }
        return info
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    async DeleteLinkman(id) {
      try{
        await this.$api.DeleteLinkMan(id)
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
     // 编辑方案基础信息
    async onEditInfo(v) {
      try{
        let result = {...this.data.project,...v,urls:v.urls.map(({url})=>{
          return url
        })}
        let project_attachment = v.urls.map(item=>{
          return {
            file_url: item.url,
            id: 0,
            project_poi_projects: this.id
          }
        })
        
        let project_types = result.project_types.split(',').map(item=> {
          return {
            name: item,
            project_poi_projects: this.id
          }
        }) 

        let furniture_types = result.furniture_types.split(',').map(item=> {
          return {
            name: item,
            project_poi_projects: this.id
          }
        }) 
        let params = {
          ...v,
          project_types,
          furniture_types,
          id: this.id,
          project_attachment: project_attachment
        }

        // params.delivery_time = this.dataFormat(params.delivery_time, 'YYYY-MM-DD')
        // params.invitation_time = this.dataFormat(params.invitation_time, 'YYYY-MM-DD')
        await this.$api.PutProject(params)
        this.$set(this.data.project,'list',result)
        this.is_edit = false
        this.$toast('更新成功')
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    // 编辑报备人信息
    async onReportman(v) {
      try{
        let params = {
          ...v
        }
        await this.$api.PutReportman(params)
        this.data.report_man = {...v}
        this.is_edit = false
        this.$toast('更新成功')
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    // 编辑竞争者信息
    async onSecondPart(v) {
       try{
        let params = {
          ...v,
          id: this.id,
        }
        await this.$api.PutProject(params)
        this.data.other = {...v}
        this.is_edit = false
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    // 删除项目
    async deleteProject() {
      try{
        await this.$api.DeleteProject(this.id)
        this.$router.push({name: 'project'})
        this.$toast('删除成功')
      }catch(ex) {
        this.$toast(this.$error(ex))
      }
    },
    // 撤回项目
    async revokeProject() {
      try {
        if (this.data.project && this.data.project.list && this.data.project.list.user_poi_users == '0') {
          this.$toast('请联系公司负责人进行撤回！')
          return
        }
        let tmpStep = this.flowStep.find(p=> p.name == 'wait_handle' && p.next_node == 'rescinded')
        if(tmpStep) {
          let data = {
            actionId: tmpStep.id,
            id: this.id,
          }
          await this.$api.UpdateFlowStep(data)
          this.state = tmpStep.next_node
        } else {
          await this.$api.RevokeProject(this.id)
          this.state = 'rescinded'
        }
        this.state = 'rescinded'
        this.$toast('撤回成功')
        this.refresh()
      } catch (ex) {
        this.$toast(this.$error(ex))
      }
    },
    async refresh(){ 
      let rightArrow = false
      let rightText = ''
      let rightFunc = ()=>{}
      if(this.state === 'wait' || this.state === 'rescinded') {
        rightArrow = true
        rightText = '删除项目'
        rightFunc = ()=>{
          try{
            this.isdelete = true
            // await this.$api.DeleteProject(this.id)
            // this.$router.push({name: 'project'})
            // this.$toast('删除成功')
          }catch(ex) {
            this.$toast(this.$error(ex))
          }
        }
      } else if(this.state === 'wait_handle' ){
        rightArrow = true
        rightText = '撤回项目'
        rightFunc = this.revokeProject
      } else if(this.power){
        rightArrow = true
        rightText = '完善项目'
        rightFunc = ()=>{
          this.showEdit = !this.showEdit
        }
      }
      this.$emit('on-nav', {
        leftText: '返回',
        leftArrow: true,
        rightArrow: rightArrow,
        onClickLeft: () => {
          this.$router.go(-1)
        },
        rightText: rightText,
        onClickRight: rightFunc
      })
    },
  },
  async mounted() {
    let { status, step } = this.getFlow('report_state')
    this.flowStep = step
    this.flowStatus = status
    this.id = this.$route.query.id
    let state = this.$route.query.state
    this.state = state
    this.power =  state === 'had_handle' || state === 'adopt' || state === 'had_reset'
    this.refresh()
    this.onLoad()
  },
  
}
</script>

<style lang="less" scoped>
.row {
  margin: 4px 0px;
}
.col {
  margin: 10px 0px;
}
.detail-box{
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}
</style>